<template>
  <div id="item-detail-page">
    <vs-popup classContent="popup-example" :title="$t('changePassword')" :active.sync="showModal">
      <div class="grid grid-cols-2 gap-4">
        <p>{{ $t('newPassword') }}: </p>
        <div>
          <vs-input type="password" v-validate="'required'" name="newPassword" ref="newPassword" class="w-full inputx mb-1" :placeholder="$t('newPassword')" v-model="newPassword" />
          <span class="text-danger text-sm" v-show="errors.has('newPassword')">{{ errors.first('newPassword') }}</span>
        </div>
        <p>{{ $t('confirmPassword') }}: </p>
        <div>
          <vs-input type="password" v-validate="'required|confirmed:newPassword'" name="confirmation" class="w-full inputx mb-1" :placeholder="$t('confirmPassword')" v-model="passwordConfirm" />
          <span class="text-danger text-sm" v-show="errors.has('confirmation')">{{ errors.first('confirmation') }}</span>
        </div>

      </div>
      <div class="flex mt-5">
        <vs-button @click="updatePassword()" color="primary" class="mt-5 ml-auto" type="filled">{{ $t('update') }}</vs-button>
      </div>
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else>
      <vs-alert color="danger" :active.sync="isBlocked">
        <span>{{ $t('user') }} {{ $t('isBlocked') }}. </span>
      </vs-alert>

      <vx-card class="mt-6" v-if="isMounted">
        <vs-row class="mb-3">
          <h3 class="mr-auto">{{ user.name }}</h3>
          
          <vs-button type="border" color="warning" @click.prevent="changePassword" class="py-3 px-5 mb-4 mr-4 rounded-lg">
            {{ $t('changePassword') }}
          </vs-button>
          <vs-button type="border" color="primary" @click.prevent="editUser" class="py-3 px-5 mb-4 mr-4 rounded-lg">
            <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
            {{ $t('edit') }}
          </vs-button>
          <vs-button type="filled" :color="isBlocked ? 'primary' : 'danger' " @click.prevent="confirmChangeStatus(user.isBlocked)" class="p-3 mb-4 rounded-lg">
            <template v-if="!isBlocked">
            <feather-icon icon="LockIcon" svgClasses="h-4 w-4" />
              {{ $t('block') }}
            </template>
            <template v-else>
            <feather-icon icon="UnlockIcon" svgClasses="h-4 w-4" />
              {{ $t('unblock') }}
            </template>
          </vs-button>
          <vs-button v-if="isBlocked" type="filled" color="danger" @click="confirmDelete" class="py-3 px-5 mb-4 ml-4 rounded-lg">
            <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
            {{ $t('delete') }}
          </vs-button>

          <!-- <div class="btn-delete p-3 mb-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-danger border border-solid border-danger" @click="deleteData(user.uuid)">
            <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base">Delete</span>
          </div> -->
        </vs-row>
        <vs-table stripe :data="userProps">
          <template slot="thead">
            <vs-th>{{ $t('property') }}</vs-th>
            <vs-th>{{ $t('value') }}</vs-th>
          </template>
          <template>
            <vs-tr v-for="(prop, idx) in userProps" :key="idx">
              <vs-td>
                <span class="uppercase font-medium">{{ $t(`props.${prop}`) }}</span>
              </vs-td>
              <vs-td v-if="user[prop] === true || user[prop] === false">
                <vs-chip :color="user[prop] ? 'primary' : 'danger'">
                  <feather-icon v-if="user[prop]" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
              <vs-td v-else-if="prop === 'addresses'">
                <p v-for="address in user.addresses" :key="address.id">{{getRegion(address.regionId)}}, {{ address.full }}</p>
              </vs-td>
              <vs-td v-else-if="prop === 'createdAt' || prop === 'updatedAt'">
                {{ new Date(user[prop]).toLocaleString('ru-RU') }}
              </vs-td>
              <vs-td v-else>
                {{ user[prop] }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

      </vx-card>

      <vx-card v-if="isMounted" class="mt-5">
        <vs-table hoverFlat ref="table" :data="data">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <div class="flex">
              <h4 class="text-primary">{{ $t('orders') }}:</h4>
            </div>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentx * itemsPerPage - (itemsPerPage - 1) }} - {{ orders.pagination.all - currentx * itemsPerPage > 0 ? currentx * itemsPerPage : orders.pagination.all }} of {{ orders.pagination.all }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="changeItemsPerPage(4)">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(10)">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(15)">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(20)">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

          </div>

          <template slot="thead">
            <vs-th>{{ $t('fields.code') }}</vs-th>
            <vs-th>{{ $t('user') }}</vs-th>
            <vs-th>{{ $t('deliveryTime') }}</vs-th>
            <vs-th>{{ $t('note') }}</vs-th>
            <vs-th>{{ $t('status') }}</vs-th>
            <!-- <vs-th>Items</vs-th> -->
            <vs-th>{{ $t('props.createdAt') }}</vs-th>
          </template>


          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="border-0 border-t border-solid border-grey-light pt-3">

              <vs-td :data="data[indextr].code">
                <span class="font-medium">#{{ data[indextr].code }}</span>
              </vs-td>

              <vs-td :data="data[indextr]">
                {{ data[indextr].user_name }}
              </vs-td>
              
              <vs-td :data="data[indextr].delivery_time">
                {{ data[indextr].delivery_time }}
              </vs-td>

              <vs-td :data="data[indextr].note">
                {{ data[indextr].note }}
              </vs-td>

              <vs-td>
                <vs-chip :color="getOrderStatusColor(data[indextr].status)" class="product-order-status">{{ data[indextr].status | title }}</vs-chip>
              </vs-td>

              <!-- <vs-td :data="data[indextr].supplierorder_products.length">
                {{ data[indextr].supplierorder_products.length }}
              </vs-td> -->

              <vs-td :data="data[indextr].createdAt">
                {{ new Date(data[indextr].createdAt).toLocaleString('ru-RU') }}
              </vs-td>

              <template class="expand-order bg-primary" slot="expand">
                <div class="con-expand-orders w-full pl-3">
                  <div class="con-btns-user flex items-center justify-between">
                    <div class="con-userx flex items-center justify-start">
                      <!-- <vs-avatar :badge="tr.id" size="45px" :src="`https://randomuser.me/api/portraits/women/${indextr}.jpg`" /> -->
                      <span class="pt-1">Order: </span><h3>#{{ data[indextr].code }}</h3>
                    </div>
                    <div class="flex">
                      <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-success text-primary" @click.stop="showPage(tr.id)" />
                      <!-- <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary text-primary" class="ml-2" @click.stop="editData(tr.uuid)" /> -->
                      <!-- <feather-icon icon="RepeatIcon" svgClasses="w-5 h-5 hover:text-primary text-primary" class="ml-2" @click.stop="changeStatus(tr.uuid, tr.isBlocked)" /> -->
                      <!--<vs-button type="border" size="small" icon-pack="feather" icon="icon-eye" class="mr-2" @click="showPage(data[indextr].id)"></vs-button>
                      <vs-button type="border" size="small" icon-pack="feather" icon="icon-edit" color="success" class="mr-2"></vs-button>
                      <vs-button type="border" size="small" icon-pack="feather" icon="icon-trash" color="danger"></vs-button> -->
                    </div>
                  </div>
                  <div class="items-container border rounded-lg overflow-hidden border-grey-light border-solid mt-3">
                    <div class="vx-row w-full ml-0 border-b border-0 border-solid border-grey-light pt-2 px-3 pb-1" style="background: #ededed;">
                      <div class="w-1/12 font-medium">#</div>
                      <div class="w-1/12 font-medium">{{ $t('fields.code') }}</div>
                      <div class="w-8/12 font-medium">{{ $t('fields.title') }}</div>
                      <div class="w-2/12 font-medium">{{ $t('fields.amount') }}</div>
                    </div>
                    <!-- <div v-for="(item, idx) in data[indextr].supplierorder_products" :key="item.id" class="vx-row ml-0 p-3">
                      <div class="w-1/12">{{ idx + 1 }}</div>
                      <div class="w-1/12">{{ item.code }}</div>
                      <div class="w-8/12">{{ item.name_tm }}</div>
                      <div class="w-2/12 font-semibold">{{ item.Soproducts.quantity }}pcs</div>
                    </div> -->
                    <div v-for="(item, idx) in data[indextr].order_products" :key="item.uuid" class="vx-row ml-0 p-3">
                      <div class="w-1/12">{{ idx + 1 }}</div>
                      <div class="w-1/12">{{ item.code }}</div>
                      <div class="w-8/12">{{ item[`name_${$i18n.locale}`] }}</div>
                      <div class="w-2/12 font-semibold">{{ item['Orderproducts'].quantity }}</div>
                    </div>
                  </div>
                  <!-- <vs-list>
                    <vs-list-item>tr.email</vs-list-item>
                    <vs-list-item icon-pack="feather" icon="icon-globe" title="tr.website"></vs-list-item>
                  </vs-list> -->
                </div>
              </template>
            </vs-tr>
          </template>
        </vs-table>
        <vs-row class="mt-4">
          <vs-pagination :total="pages" :max="9" v-model="currentx" @change="paginateTo"></vs-pagination>
          <!-- <vs-button v-if="orders.pagination.next" class="mx-auto" color="primary">Load more</vs-button> -->
        </vs-row>
      </vx-card>
    </template>

  </div>
</template>

<script>
export default{
  data () {
    return {
      currentx: 1,
      itemsOffset: 0,
      itemsPerPage: 4,
      showModal: false,
      isMounted: false,
      isLoading: true,
      regions: { data: [] },
      user: {},
      orders: {
        pagination: {},
        data: []
      },
      newPassword: '',
      passwordConfirm: ''
    }
  },
  computed: {
    isBlocked () { return this.user.isBlocked },
    data () { return this.orders.data },
    userProps () { return Object.keys(this.user).filter(el => el !== 'device_token') },
    pages () { return Math.ceil(this.orders.pagination.all / this.itemsPerPage) }
  },
  async created () {
    await this.fetchData()
    this.isMounted = true
  },
  methods: {
    editUser () { this.$router.push(`/users/${this.user.uuid}/edit`).catch(() => {}) },
    changeItemsPerPage (count) {
      this.itemsPerPage = count
      this.fetchOrders('changeItemsPerPage')
    },
    paginateTo () {
      this.itemsOffset = this.itemsPerPage * (this.currentx - 1)
      this.fetchOrders()
    },
    getRegion (id) {
      return this.regions.data.data.find(reg => reg.id === id)[`name_${this.$i18n.locale}`]
    },
    getOrderStatusColor (status) {
      if (status === 'on_hold')   return 'warning'
      if (status === 'pending')   return 'warning'
      if (status === 'rejected') return 'danger'
      if (status === 'delivered') return 'success'
      if (status === 'not_delivered') return 'danger'
      if (status === 'canceled')  return 'danger'
      return 'primary'
    },
    async fetchData () {
      this.regions = await this.$http.get('/regions')
      await this.$http.get(`/users/${this.$route.params.id}`).then(response => {
        this.user = response.data
        this.isLoading = false
      }).catch(error => {
        this.error_occured = true
        this.error_msg = error.message
        this.isLoading = false
        // console.error(error)
      })
    },
    async fetchOrders () {
      await this.$http.get('/users/orders', {
        params: { userId: this.user.id, limit: this.itemsPerPage, offset: this.itemsOffset }
      }).then(res => {
        this.orders = res.data
      }).catch(err => { return (err) })
    },
    confirmChangeStatus (blocked) {
      let color = ''
      let title = ''
      let text = ''
      let acceptText = ''
      const cancelText = this.$t('cancel')
      if (blocked) {
        color = 'primary'
        title = this.$t('unblock')
        text = `${this.$t('confirmUnblock')} "${this.user.name}"`
        acceptText = this.$t('unblock')
      } else {
        color = 'danger'
        title = this.$t('block')
        text = `${this.$t('confirmBlock')} "${this.user.name}"`
        acceptText = this.$t('block')
      }
      this.$vs.dialog({ type: 'confirm', color, title, text, accept: this.changeStatus, acceptText, cancelText })
    },
    async changeStatus () {
      await this.$http.patch(`/users/edit/${this.user.uuid}`, { isBlocked: !this.user.isBlocked })
        .then(() => {
          this.notify('success', `${this.$t('user')} ${this.$t('status')} ${this.$t('notify.sUpdated')}`)
          this.fetchData()
        }).catch(err => { this.notify('error', err.message) })
    },
    changePassword () {
      this.newPassword = ''
      this.passwordConfirm = ''
      this.showModal = true
    },
    updatePassword () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/users/edit/${this.user.uuid}`, {
            password: this.newPassword
          }).then(() => {
            this.showModal = false
            // console.log(response)
            this.notify('success', `${this.$t('user')} ${this.$t('password')} ${this.$t('notify.sUpdated')}`)
            this.fetchData()
          }).catch(err => {
            this.notify('error', err.message)
          })
        } else {
          this.notify('error', this.$t('notify.formInvalid'))
        }
      })
    },
    confirmDelete () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `${this.$t('confirmDelete')} "${this.user.name}"`,
        accept: this.deleteUser,
        acceptText: this.$t('delete'),
        cancelText: this.$t('cancel')
      })
    },
    deleteUser () {
      this.$http.delete(`/users/delete/${this.user.id}`).then(() => {
        // console.log(response)
        this.notify('success', `${this.$t('user')} ${this.$t('notify.sDeleted')}`)
        this.$router.push('/users')
      }).catch(err => {
        this.notify('error', err.message)
      })
    },
    notify (status, msg) {
      if (status === 'success') {
        this.$vs.notify({
          title: this.$t('notify.success'),
          text: msg,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
      } else {
        this.$vs.notify({
          title: this.$t('notify.error'),
          text: msg,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    }
  }
}
</script>

<style lang="scss">

@import "@/assets/scss/vuexy/_variables.scss";

#item-detail-page {
  .color-radio {
    height: 2.28rem;
    width: 2.28rem;
    > div {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .item-features {
    background-color: #f7f7f7;

    .theme-dark & {
      background-color: $theme-dark-secondary-bg;
    }
  }

  .user-sales-meta-list {
    .vs-list--icon {
      padding-left: 0;
    }
  }

  .related-user-swiper {
      // padding-right: 2rem;
      // padding-left: 2rem;

    .swiper-wrapper {
      padding-bottom: 2rem;

      > .swiper-slide {
        background-color: #f7f7f7;
        box-shadow: 0 4px 18px 0 rgba(0,0,0,0.1), 0 5px 12px 0 rgba(0,0,0,0.08) !important;

        .theme-dark & {
          background-color: $theme-light-dark-bg;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      transform: scale(.5);
      filter: hue-rotate(40deg);
    }

    .swiper-button-next {
      right: 0
    }

    .swiper-button-prev {
      left: 0;
    }
  }
}
</style>
